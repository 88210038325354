import { Link } from "react-router-dom";
import Account from "../../elements/account";
import DownloadBlock from "../../elements/download";
import { useState, useEffect } from "react";
import axios from "axios";

function Accountstudio() {
  const [sub_status, setSub_status] = useState(false);
  const [sub_active, setSub_active] = useState("");
  const [sub_payment_status, setSub_payment_status] = useState("");

  const [erreur, seterreur] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchStripeData = async () => {
      try {
        const response = await axios.get("/api:o5YBY4Ce/subscription/get", {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          },
        });

        if (response.data.subscription_infos.subscription_status == true) {
          setSub_active(response.data.subscription_infos.subscription_status);
          setSub_payment_status(response.data.subscription_infos.subscription_current_payment_status);
          seterreur(true);
        

        } else {
          // Gérer le cas d'erreur en définissant l'erreur
          seterreur(false);
        }
      } catch (error) {
        console.error("Error:", error);
        seterreur(false);
      }
    };

    fetchStripeData();
  }, [token]);

  const log_out = async () => {
    try {
      const response = await axios.delete("api:bUlrPTKD/studio/all_sessions", {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
        },
      });

      if (response.status === 200) {
        // Récupérer les données pertinentes de la réponse Stripe

        alert("Success");
      } else {
        // Gérer le cas d'erreur si nécessaire
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  return (
    <div>
    <Account />
    <p className="title-left mb-4">Your subscription</p>
   
    <p  className={`txt-left-g mt-8 italic ${
            erreur == true ? "hidden" : "block"
          }`}>No Studio subscription</p>
            
    <div  
    
    className={`ml-4 mr-4 mt-10 ${
      erreur == false ? "hidden" : "block"
    }`}
>

      <div  className="w-full border-2 flex border-indigo-600 rounded-2xl p-4 mt-6 mb-4">
        <div className="error-input-standard-line float-left">
          <p className="text-indigo-600 float-left mr-3">
           
            <b>➜ Active subscription.</b>
          </p>
          <br></br>
          <div className="float-left mt-2">
          <p className="txt-left-g-b float-left ">
            View invoices for more informations.
          </p>
          </div>
        </div>
      </div>

      <div className=" pb-6">
      <div className="mb-3">
    
          <p className="title-left pt-10 pb-5 ">Sessions</p>
          <p className="w-full mb-3 text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="float-left mr-2 mt-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>You are limited to a maximum of 2 sessions, which means two screens simultaneously. Log out from all sessions if you are unable to connect due to too many connections.
          </p>  
          <Link
            onClick={log_out}
            to=""
            className="txt-center-g rounded-2xl bg-gray-900 hover:bg-gray-800 border w-64 border-gray-800 p-2 pl-6 pr-6 button-standard-line flex pt-1.5 float-left "
          >
            Log out from all  sessions
          </Link>
        </div>
        <div className="float-left absolute">
        </div>
      </div>
        <p className="title-left pt-16">Download</p>
        <DownloadBlock />

        <div>
          <p className="title-left pt-16 pb-5">Studio invoices</p>
          <Link
            onClick={scrollToTop}
            to="/account/orders"
            className="txt-center-g rounded-2xl bg-gray-900 hover:bg-gray-800 border border-gray-800 p-2 pl-6 pr-6 button-standard-line flex pt-1.5 float-left "
          >
            View invoices          </Link>
        </div>
        <div className="w-64">
        <Link
          onClick={scrollToTop}
          to="/abort"
          className="mb-20 block text-rose-200 text-mt rounded-2xl bg-gray-900 hover:bg-gray-800 border border-rose-200 p-1.5 pl-6 pr-6 button-standard-line pt-1.5 mx-auto text-center mt-24"
        >
          Cancel subscription
        </Link>
        </div>
        
      </div>  
    </div>
  );
}
export default Accountstudio;
