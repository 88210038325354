import Banner from "../elements/banner";
import LogoAbyss from "../medias/logoabyss.svg";
import { Link } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

function Header() {
  const location = useLocation();
  const hiddenRoutes = ["/start"];
  const isHeaderVisible = !hiddenRoutes.includes(location.pathname);
  const [phoneHeader, setPhoneHeader] = useState(false);

  const Dropdown = useRef(null);
  const Arrow = useRef(null);
  const handleShow = () => {
    if (Dropdown.current) {
      setPhoneHeader(!phoneHeader);
      //Dropdown.current.classList.toggle("hidden");
      //Arrow.current.classList.toggle("rotate-180");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  const pricingRef = useRef(null);

  const scrollPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const [txtlogin, settxtlogin] = useState("Log in");
  const [loginredirect, setloginredirect] = useState("/login");

  const token = localStorage.getItem("token");
  useEffect(() => {
    const handleButtonClick = async () => {
      try {
        const response = await axios.get("/api:5FCR-wUS/user/getinfos", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          // Vérifiez si des données sont renvoyées
          setloginredirect("/account/home");
          settxtlogin("Account");
        } else {
          settxtlogin("Log In");
        }
      } catch (error) {
        settxtlogin("Log In");
      }
    };

    handleButtonClick(); // Appel de la fonction à l'initialisation
  }, [token]);

  return (
    <header>
      {isHeaderVisible && (
        <div>
          <div className="h-32 max-w-[1300px]"></div>

          <div className="fixed top-0 bg- desktop:bg-opacity-0  desktop:h-20 opacity-95  left-0 w-full z-40">

            <div
              className={`bg-gray-950 desktop:h-24 ${
                phoneHeader ? "h-96" : "h-20"
              } `}
            >
              <div className=" mx-auto max-w-[1400px]">
                <header className="">
                  <div className="block desktop:hidden ml-4  fixed desktop:relative desktop:ml-6 h-full pt-8 float-left w-12">
                    <div onClick={handleShow} className="w-6 mb-2 ml-2 mt-1">
                      <div
                        className={`w-full h-0.5 mb-2 rounded-full duration-200 bg-gray-400 opacity-100  ${
                          phoneHeader
                            ? "rotate-45 h-[3px] translate-y-[9px]"
                            : "rotate-0"
                        }`}
                      ></div>
                      <div
                        className={`w-full h-0.5 mb-2 duration-200 rounded-full bg-gray-400 opacity-100 ${
                          phoneHeader ? "hidden" : "block"
                        }`}
                      ></div>
                      <div
                        className={`w-full h-0.5 duration-200 rounded-full
                         bg-gray-400 opacity-100  ${
                           phoneHeader ? "h-[3px] -rotate-45 " : "rotate-0"
                         }`}
                      ></div>
                    </div>

                    <div
                      className={`   ${
                        phoneHeader ? `desktop:hidden block` : `hidden`
                      }`}
                    >
                      <div className=" ">
                

                        <div className=" left-4 top-4">
                          <br></br>
                          <Link
                            to="/pricing"
                            onClick={() => {
                              scrollToTop();
                            }}
                            className="float-left ml-2 text-gray-400 mt-4 "
                          >
                            Pricing
                          </Link>
                          <br></br>
                          <Link
                            onClick={() => {
                              handleShow();
                              scrollToTop();
                            }}
                            to="/download"
                            className="float-left ml-2 mt-4 text-gray-400 mb-4"
                          >
                            Download
                          </Link>
                          <Link
                            onClick={() => {
                              handleShow();
                              scrollToTop();
                            }}
                            to="/learn"
                            className="float-left ml-2  text-gray-400 mb-4"
                          >
                            Learn
                          </Link>
                          <Link
                            onClick={() => {
                              handleShow();
                              scrollToTop();
                            }}
                            to="/soundkits"
                            className="float-left ml-2  mb-4"
                          >

                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-body">
                    <div className="desktop:mt-4 desktop:h-11 desktop:pt-1 desktop:pl-6 desktop:float-left desktop:text-left  text-center pt-7 ">
                      <Link
                        onClick={scrollToTop}
                        className="flex justify-center"
                        to="/"
                      >
                        <div className=" -translate-y-2">
                          <p className="text-center font-akira text-3xl  text-white">
                            studio 
                          </p>
                          <p className="text-right font-mt text-sm text-gray-400 -translate-y-1">
                             by abyss®
                         
                          <p className="text-center font-akira translate-y-3 ml-1 text-sm  text-white">
                          
                          </p> </p>
                        </div>
                      </Link>
                    </div>
                    <button>
                      <Link
                        onClick={scrollToTop}
                        to={loginredirect}
                        className=" absolute -translate-y-[70px] translate-x-2 right-0 w-16 text-gray-400 desktop:hidden"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          fill="currentColor"
                          class="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                        </svg>
                      </Link>
                    </button>
                    <div className="float-right mt-3 h-7  pt-4 desktop:block hidden">
                      <button>
                        <Link
                          onClick={scrollToTop}
                          to={loginredirect}
                          className="btn-left mr-6 "
                        >
                          {txtlogin}
                        </Link>
                      </button>
                      <div
                        className={`  inline-block ${
                          txtlogin == "Log In"
                            ? `hidden desktop:inline-block `
                            : `hidden`
                        }`}
                      >
                        <Link
                          onClick={() => {
                            scrollToTop();
                          }}
                          to="/pricing"
                          className="hover:scale-105  mr-4 p-2 px-12 rounded-xl bg-indigo-600 pl-5 pr-5 hover:duration-200"
                        >
                          Start 7-day trial{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="inline-block ml-2 mr-1 -translate-y-0.5"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z" />
                          </svg>{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="inline-block  mr-1 -translate-y-0.5"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                          </svg>{" "}
                         
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="desktop:float-right mt-3 h-7 relative pt-4 desktop:hidden block"></div>
                    <div className="invisible desktop:visible float-right pt-1 pr-4">
                      <div className="float-right ml-2 pt-2 mt-4 h-7 pl-2 hidden desktop:block">
                        <Link
                          onClick={scrollToTop}
                          className="btn-left"
                          to="/learn"
                        >
                          Learn
                        </Link>
                      </div>
                      <div className="float-right ml-2 pt-2 mt-4 h-7 pl-2 hidden desktop:block">
                        <Link
                          onClick={scrollToTop}
                          className="btn-left"
                          to="/download"
                        >
                          Download
                        </Link>
                      </div>
                      <div className="float-right ml-2 pt-2 mt-4 h-7 pl-2 hidden desktop:block">
                        <Link
                         to="/pricing"
                         onClick={() => {
                          scrollToTop();
                        }}
                          className="btn-left"

                        >
                          Pricing
                        </Link>
                      </div>

                      <div
                        id="dropdown"
                        ref={Dropdown}
                        className="hidden duration-150 z-10 h-36 w-32 mt-3 translate-y-12 translate-x-4 rounded-2xl bg-gray-900 border-2 border-gray-800"
                      >
                        <Link
                          onClick={() => {
                            handleShow();
                            scrollToTop();
                          }}
                          to="/studio"
                          className="float-left ml-4  mt-1.5 -translate-y-5"
                        >
                          Discover
                        </Link>
                        <br></br>
                        <Link
                          onClick={() => {
                            scrollToTop();
                          }}
                          to="/pricing"
                          className="float-left ml-4 mt-1 -translate-y-5"
                        >
                          Pricing
                        </Link>
                        <br></br>
                        <Link
                          onClick={() => {
                            handleShow();
                            scrollToTop();
                          }}
                          to="/download"
                          className="float-left ml-4 mt-1 -translate-y-5"
                        >
                          Download
                        </Link>

                        <Link
                          onClick={() => {
                            handleShow();
                            scrollToTop();
                          }}
                          to="/learn"
                          className="float-left ml-4 mt-1 -translate-y-5 mb-16"
                        >
                          Learn
                        </Link>
                      </div>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
export default Header;
