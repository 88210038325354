import { Link } from "react-router-dom";
import Wallpaper from "../elements/wallpaper";
import { useState } from "react";
import Eye from "../medias/eye.svg";
import EyeSlash from "../medias/eye-slash.svg";
import X from "../medias/X.svg";
import V from "../medias/V.svg";
import { useEffect } from "react";
import { useRef } from "react";
import LogoAbyss from "../medias/logoabyss.svg";
import axios from "axios";

function GoStripeSignup(props) {
  const [isLoading, setIsLoading] = useState();
  const [token,setToken] = useState(localStorage.getItem("token"));
const [link, setLink] = useState();

  const [usernameTouched, setUsernameTouched] = useState(false);
  const [username, setUsername] = useState(""); // fonction verify mdp
  const usernameValidLength = username.length >= 3; //taille du mdp

  const [validmail, setValidmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    validmail
  );

  const [passwordisVisible, setPasswordisVisible] = useState(false); //fonction hide show mdp

  const [passwordTouched, setPasswordTouched] = useState(false);
  const [password, setPassword] = useState(""); // fonction verify mdp
  const passwordHasValidLength = password.length >= 8; //taille du mdp
  const passwordHasNumber = /\d/.test(password); //Nombre ?
  const passwordHasLetter = /[A-Z]/.test(password); //lettre ?
  const [newsletterOn, setNewsletterOn] = useState(true); //fonction hide show mdp

  const [errormsg, setErrormsg] = useState("");
  const error = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  const [plan, setPlan] = useState(null);

  
  const getStripe = async () => {
    const storedPlan = localStorage.getItem('selectedSubscription');
    const tokens = localStorage.getItem('token');
    if (storedPlan) {
      setPlan(storedPlan);
    }
    
    try {

      // Faire la requête POST avec axios
      const response = await axios.post("/api:o5YBY4Ce/subscription/session", {
        price_id : storedPlan
      }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: tokens
        }
      });
      /*if (response.data == "Already a subscription") {
        alert("Already a subscription");
      }
      else */if (response.status == 200) {
        // Mettre à jour l'URL
        setLink(response.data.result_1);

      } else {
        // Mettre à jour la page et le poids en cas d'erreur
        alert("Error !");
        window.location.reload();
      }
    } catch (error) {
      // Gérer les erreurs et mettre à jour la page et le poids
      alert("Catch during stripe call");
      window.location.reload();
    }
  };
  

  const registerUser = async () => {
    try {
      const response = await axios.post("/api:5FCR-wUS/auth/signup", {
        name: username,
        email: validmail,
        password: password,
      });
  
      if (response.status == 200) {
        sendNewsletter();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            localStorage.removeItem("token");
            localStorage.setItem("token", response.data.token);
            getStripe();
      } else {
        error.current.classList.remove("hidden");
        setErrormsg("");
      }
    } catch (error) {
      setErrormsg("An error occurred", error);
    }
  };

  

  const sendNewsletter = () => {
    if (newsletterOn) {
    
    axios
      .post("/api:swpGnftB/newsletter", {
        email: validmail,
      })
      .then(
        (response) => {
          if (response.data.success) {
            console.log("Success newsletter")
          }
        },
        (error) => {
            console.log("error newsletter")
        }
      );

    }
  };


  return (
    <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-4 ">
          <p className="title-center">Signup to start</p>
        </div>

        <div className="h-36">
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 border-gray-800 ${
              !usernameValidLength && usernameTouched
                ? "border-rose-200"
                : "border-gray-800"
            } `}
            type="text"
            onChange={(j) => setUsername(j.currentTarget.value)}
            onBlur={() => setUsernameTouched(true)}
            placeholder="Producer Name"
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              !emailIsValid && emailTouched
                ? " border-rose-200"
                : "border-gray-800 "
            }`}
            type="email"
            placeholder="Email"
            onChange={(i) => setValidmail(i.currentTarget.value)}
            onBlur={() => setEmailTouched(true)}
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              !emailIsValid &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter &&
              !passwordTouched
                ? " border-rose-200"
                : "border-gray-800 "
            }`}
            type={passwordisVisible ? "text" : "password"}
            placeholder="Password"
            id="password-field"
            onChange={(e) => setPassword(e.currentTarget.value)}
            onBlur={() => setPasswordTouched(true)}
          ></input>
          <div className="relative right-0 z-10">
            <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-4"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>

        <div className="mt-8">
          <p
            className={` ${
              passwordHasValidLength ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 8 characters{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasValidLength ? V : X}
            />
          </p>
          <br />
          <p
            className={` ${
              passwordHasLetter ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 Uppercase{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasLetter ? V : X}
            />
          </p>
          <br />
          <p
            className={`  ${
              passwordHasNumber ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 number{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasNumber ? V : X}
            />
          </p>
          <br />
        </div>

        <p ref={error} className="hidden text-left  mb-4 text-rose-200">
          This email is already linked to an account,{" "}
          <Link to="/login" onclick={scrollToTop}>
            <u>Log In</u>
          </Link>
        </p>

        <p className="text-left mb-4 text-rose-200">{errormsg}</p>

        <div className=" mb-3">
          <p className="txt-left-g">
            <button
              onClick={() => setNewsletterOn(!newsletterOn)}
              className={` ${
                newsletterOn ? " border-indigo-600" : " translate-x-0"
              } mr-2 float-left w-8 translate-y-0.5 h-[20px] border-2 bg-gray-900 border-gray-400 rounded-2xl `}
            >
              <div
                className={` ${
                  newsletterOn
                    ? " translate-x-3 bg-indigo-600"
                    : " translate-x-0"
                } w-[11px] h-[11px] duration-200  ml-1 rounded-full bg-gray-400 `}
              ></div>
            </button>
            I want to receive occasional news by email. No spam.
          </p>
        </div>
        <div className="mt-5 mb-6">
          <p className="txt-left-g">
            By subscribing you agree to the{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Privacy Policy
            </Link>
          </p>
        </div>
        <div className="mb-20">
          <Link
            onClick={() => {
              setIsLoading();
              registerUser();
            }}
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${
              emailIsValid &&
              usernameValidLength &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Start 7-day trial
          </Link>
        </div>
        <div className="mt-3 mb-16">
          <p className="txt-center-g">
            Have an account ?{" "}
            <Link
              to="/studiologin"
              onClick={scrollToTop}
              className="txt-center-g-u"
            >
              Log in
            </Link>
          </p>
        </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GoStripeSignup;
