import { Link } from "react-router-dom";
import backgroundImage from "../medias/bluewater.png";

function Account() {
  const scrollToTop = () => {
    const element = document.getElementById("1");
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.pageYOffset - 140;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  
  const RemoveToken = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };


  return (
    <div className="mb-6">
      <div className="mb-4">

 
        <Link id="1" onClick={scrollToTop} to="/account/home" className="title-left mb-12">
          Dashboard
        </Link>
      </div>
      <div className="flex flex-wrap">
      <div className="float-left me-4">
        <Link onClick={scrollToTop} to="/account/home" className="btn-left-g">
          Home
        </Link>
      </div>
      <div className="float-left me-4">
        <Link onClick={scrollToTop} to="/account/profile" className="btn-left-g">
          Profile
        </Link>
      </div>
      <div className="float-left me-4">
        <Link onClick={scrollToTop} to="/account/studio" className="btn-left-g">
          STUDIO
        </Link>
      </div>
      <div className="float-left me-4">
        <Link onClick={scrollToTop} to="/account/soundkits" className="btn-left-g">
          Soundkits
        </Link>
      </div>

      <div className="float-left me-4">
        <Link onClick={scrollToTop} to="/account/help" className="btn-left-g">
          Help
        </Link>
      </div>
      <div className="float-left me-4">
        <Link onClick={RemoveToken}  className="btn-left-g">
          Logout
        </Link>
      </div>
            </div>
      <div className="border border-gray-900 mt-6 "></div>
    </div>
  );
}
export default Account;
