import React from "react";
import Michenaud from "../medias/michenaud.svg";
import Advice from "../elements/advice";
import Wallpaper from "../elements/wallpaper";
import Product from "../elements/product";
import Carousel from "../elements/carrousel"; // Assurez-vous que le chemin est correct
import { Link } from "react-router-dom";
import Wall from "../medias/wall.png";
import ReactPlayer from "react-player";
import DAW from "../medias/daw.png";
import Plus from "../medias/+.svg";
import Pro from "../medias/pro.svg";
import { useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Loader from "./pricing";




function Studio() {
  const [showPopup, setShowPopup] = useState(false);
  const [newsletterOn, setNewsletterOn] = useState(true);
  const audioSrc = "../medias/flamenco_example.wav";

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollPricing= () => {
    const element = document.getElementById('pricing');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubscriptionSelect = (subscriptionType) => {
    localStorage.setItem('selectedSubscription', subscriptionType);
  };

  return (
    <div>
      

    <div>
      <img
        src={Wall}
        className="absolute -z-50 blur-lg w-full left-0 top-20"
        alt="Description of the image"
      />

      <div className="flex items-center justify-center">
        <div className="flex">
          <p className="text-center font-akira text-5xl mt-24 text-white">
            studio
          </p>
          <p className="text-center font-akira translate-y-6 ml-1 text-lg mt-24 text-white">
            ®
          </p>
        </div>
      </div>
      <p className="text-center font-mt block text-lg mt-8 mb-3 text-gray-400">
        <b>All-in-one</b> VST made for audio <b>producers.</b> Focus only on your <b>creativity</b>
      </p>
      <div className="w-64 mx-auto">
        <Link
          onClick={scrollPricing}
          className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
        >
          Start 7-day trial
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="inline-block ml-2 mr-1 -translate-y-0.5"
            viewBox="0 0 16 16"
          >
            <path d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="inline-block  mr-1 -translate-y-0.5"
            viewBox="0 0 16 16"
          >
            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
          </svg>{" "}
          ➜
        </Link>
      </div>
      <div className="flex flex-wrap w-full mt-24 text-center">
        <div className="w-full desktop:w-1/4 p-4">
          <div className="bg-gray-100 p-6 rounded-lg">
            <h2 className="text-4xl font-akira mb-2">42+</h2>
            <p className="text-gray-700">Acoustic presets</p>
          </div>
        </div>
        <div className="w-full desktop:w-1/4 p-4">
          <div className="bg-gray-100 p-6 rounded-lg">
            <h2 className="text-4xl font-akira mb-2">54+</h2>
            <p className="text-gray-700">Synth presets</p>
          </div>
        </div>
        <div className="w-full desktop:w-1/4 p-4">
          <div className="bg-gray-100 p-6 rounded-lg">
            <h2 className="text-4xl font-akira mb-2">36+</h2>
            <p className="text-gray-700">Phrases presets</p>
          </div>
        </div>
        <div className="w-full desktop:w-1/4 p-4">
          <div className="bg-gray-100 p-6 rounded-lg">
            <h2 className="text-4xl font-akira mb-2">300+</h2>
            <p className="text-gray-700">Midis files</p>
          </div>
        </div>
      </div>

      <Carousel />
    </div>

    <div>
      <Wallpaper />
    </div>
   
   <p className="title-center mt-32 mb-8">
    Discover in video
    </p>
    <div className="relative">
    <img
        src={Wall}
        className="absolute -z-50 blur-xl w-full left-0 top-20"
        alt="Description of the image"
      />
    </div>
    <div className="desktop:mx-[15%]">
    <div className="relative pt-[56.25%]  rounded-2xl overflow-hidden">
      <ReactPlayer
        url="https://www.youtube.com/watch?v=07GKXvCwbkA"
        controls
        className="absolute top-0 left-0"
        width="100%"
        height="100%"
      />
    </div>
    </div>

<Loader/>

   </div>
  );
}
export default Studio;
